import "react-toastify/dist/ReactToastify.css";
import "./style/toastify.css";

import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { MotionConfig } from "framer-motion";
import React, { lazy, Suspense } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { PageLoader } from "./components/design-system/loader/PageLoader";
import { Geolocalised } from "./components/Geolocalised";
import { MatomoHistoryListener } from "./components/MatomoHistoryListener";
import { healthKeyV2Theme } from "./marketing-site-app-shared/themes/theme";
import store, { persistor } from "./store/store";

export const router = createBrowserRouter([{ path: "*", Component: Root }]);

const WebsiteSiteMap = lazy(
  () => import("./pages/marketing-website/WebsiteSiteMap"),
);

const Home = lazy(() => import("./pages/marketing-website/Home"));
const Employers = lazy(() => import("./pages/marketing-website/Employers"));
const Providers = lazy(() => import("./pages/marketing-website/Providers"));
const Users = lazy(() => import("./pages/marketing-website/Individuals"));
const Gyms = lazy(() => import("./pages/marketing-website/Gyms"));
const Blog = lazy(() => import("./pages/marketing-website/Blog"));
const BlogEntryPage = lazy(
  () => import("./pages/marketing-website/BlogEntryPage"),
);

const StudentDiscounts = lazy(
  () => import("./pages/marketing-website/StudentDiscounts"),
);
const About = lazy(() => import("./pages/marketing-website/About"));
const FAQs = lazy(() => import("./pages/marketing-website/FAQs"));
const Pricing = lazy(() => import("./pages/marketing-website/Pricing"));
const Contact = lazy(() => import("./pages/marketing-website/Contact"));
const ContactEmployers = lazy(
  () => import("./pages/marketing-website/ContactEmployers"),
);
const DesignPreview = lazy(() => import("./pages/DesignPreview"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));

const FindCare = lazy(
  () => import("./pages/user-platform/explore-care/FindCare/FindCare"),
);

const SearchResults = lazy(
  () =>
    import("./pages/user-platform/explore-care/SearchResults/SearchResults"),
);

const HealthCategory = lazy(
  () => import("./pages/user-platform/explore-care/HealthCategoryPage"),
);

const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));

export default function App() {
  return <RouterProvider router={router} />;
}

const HealthKeySuspense = ({ children }: { children: React.ReactNode }) => {
  return <Suspense fallback={<PageLoader />}>{children}</Suspense>;
};

const Fallback = ({ error }: FallbackProps) => {
  if ((error as Error)?.name.startsWith("ChunkLoadError")) {
    window.location.reload();
    return <PageLoader />;
  }

  return <ErrorPage />;
};

export function Root() {
  return (
    <>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            fontFamily: `Karla, Roboto, "Helvetica Neue", Arial, sans-serif`,
          },
        }}
      />
      <ThemeProvider theme={healthKeyV2Theme}>
        <MotionConfig reducedMotion="user">
          <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
              <HelmetProvider>
                <ErrorBoundary
                  fallbackRender={Fallback}
                  onReset={(details) => {
                    // Reset the state of your app so the error doesn't happen again
                    console.warn(details);
                  }}
                >
                  <Geolocalised>
                    <MatomoHistoryListener>
                      <Routes>
                        <Route
                          path="/websiteSiteMap"
                          element={
                            <HealthKeySuspense>
                              <WebsiteSiteMap />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/error"
                          element={
                            <HealthKeySuspense>
                              <ErrorPage />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/"
                          element={
                            <HealthKeySuspense>
                              <Home />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/home"
                          element={
                            <HealthKeySuspense>
                              <Home />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/industry/employers"
                          element={
                            <HealthKeySuspense>
                              <Employers />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/for-employers"
                          element={
                            <HealthKeySuspense>
                              <Employers />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/industry/providers"
                          element={
                            <HealthKeySuspense>
                              <Providers />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/industry/individuals"
                          element={
                            <HealthKeySuspense>
                              <Users />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/industry/gyms"
                          element={
                            <HealthKeySuspense>
                              <Gyms />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/student-discounts"
                          element={
                            <HealthKeySuspense>
                              <StudentDiscounts />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/FAQs"
                          element={
                            <HealthKeySuspense>
                              <FAQs />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/contact"
                          element={
                            <HealthKeySuspense>
                              <Contact />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/demo"
                          element={
                            <HealthKeySuspense>
                              <ContactEmployers />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/pricing"
                          element={
                            <HealthKeySuspense>
                              <Pricing />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/about"
                          element={
                            <HealthKeySuspense>
                              <About />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/blog"
                          element={
                            <HealthKeySuspense>
                              <Blog />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/blog/:entrySlug"
                          element={
                            <HealthKeySuspense>
                              <BlogEntryPage />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/design-preview"
                          element={
                            <HealthKeySuspense>
                              <DesignPreview />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/privacy-policy"
                          element={
                            <HealthKeySuspense>
                              <PrivacyPolicy />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/terms-of-use"
                          element={
                            <HealthKeySuspense>
                              <TermsOfUse />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/findCare"
                          element={
                            <HealthKeySuspense>
                              <FindCare />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/findCare/results"
                          element={
                            <HealthKeySuspense>
                              <SearchResults />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="/findCare/healthCategory/:healthCategory"
                          element={
                            <HealthKeySuspense>
                              <HealthCategory />
                            </HealthKeySuspense>
                          }
                        />
                        <Route
                          path="*"
                          element={
                            <HealthKeySuspense>
                              <PageNotFound />
                            </HealthKeySuspense>
                          }
                        />
                      </Routes>
                    </MatomoHistoryListener>
                  </Geolocalised>
                </ErrorBoundary>
              </HelmetProvider>
            </PersistGate>
          </Provider>
        </MotionConfig>
      </ThemeProvider>
    </>
  );
}
